module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="container-fluid">\n  <div class="row">\n    <div class="col-md-6">\n      <h1>' +
((__t = ( __('error-404-title') )) == null ? '' : __t) +
'</h1>\n      <p><strong>' +
((__t = ( __('error-code') )) == null ? '' : __t) +
' 404</strong></p>\n      <p>' +
((__t = ( __('error-404-desc') )) == null ? '' : __t) +
'</p>\n    </div>\n  </div>\n</div>\n';

}
return __p
}