module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="georap-success alert alert-success alert-dismissible" role="alert">\n  <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>\n  <span class="message">' +
((__t = ( message )) == null ? '' : __t) +
'</span>\n</div>\n';

}
return __p
}