module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="progress">\n  <div class="progress-bar progress-bar-striped active" role="progressbar" style="width: 100%">\n    <span class="sr-only">' +
((__t = ( text )) == null ? '' : __t) +
'</span>\n  </div>\n</div>\n';

}
return __p
}