module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {



if (makeLink) {
  ;
__p += '<a href="' +
((__t = ( url )) == null ? '' : __t) +
'" target="_blank" class="attachment-thumbnail ' +
((__t = ( sizeClass )) == null ? '' : __t) +
'">';

} else {
  ;
__p += '<span class="attachment-thumbnail ' +
((__t = ( sizeClass )) == null ? '' : __t) +
'">';

}

if (attachment.mimetype.startsWith('image/')) {
  ;
__p += '<img src="' +
((__t = ( attachment.thumburl )) == null ? '' : __t) +
'" title="' +
((__t = ( attachment.filename )) == null ? '' : __t) +
'">';

} else if (attachment.mimetype === 'application/pdf') {
  ;
__p += '<img src="/assets/images/thumbnails/pdf.png" title="' +
((__t = ( attachment.filename )) == null ? '' : __t) +
'">';

} else if (attachment.mimetype === 'application/vnd.ms-excel') {
  ;
__p += '<img src="/assets/images/thumbnails/xls.png" title="' +
((__t = ( attachment.filename )) == null ? '' : __t) +
'">';

} else if (attachment.mimetype === 'text/plain') {
  ;
__p += '<img src="/assets/images/thumbnails/txt.png" title="' +
((__t = ( attachment.filename )) == null ? '' : __t) +
'">';

} else {
  ;
__p += '<img src="/assets/images/thumbnails/file.png" title="' +
((__t = ( attachment.filename )) == null ? '' : __t) +
'">';

}

if (makeLink) {
  ;
__p += '</a>';

} else {
  ;
__p += '</span>';

}

;
__p += '\n';

}
return __p
}