module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 availableLocales.forEach(function (locale, index) { ;
__p += '\n  ' +
((__t = ( index > 0 ? '-' : '' )) == null ? '' : __t) +
'\n  <a href="?locale=' +
((__t = ( locale )) == null ? '' : __t) +
'" lang="' +
((__t = ( locale )) == null ? '' : __t) +
'" target="_self">\n    ' +
((__t = ( __('locale-native', locale) )) == null ? '' : __t) +
'\n  </a>\n';
 }) ;
__p += '\n';

}
return __p
}