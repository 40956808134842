module.exports = {
  'en': {
    prefix: '',
    suffix: ' ago',
    seconds: 'a few seconds',
    minute: 'a minute',
    minutes: '%d minutes',
    hour: 'an hour',
    hours: '%d hours',
    day: 'a day',
    days: '%d days',
    month: 'a month',
    months: '%d months',
    year: 'a year',
    years: '%d years',
  },
  'fi': {
    prefix: '',
    suffix: ' sitten',
    seconds: 'muutama sekunti',
    minute: 'noin minuutti',
    minutes: '%d minuuttia',
    hour: 'noin tunti',
    hours: '%d tuntia',
    day: 'noin päivä',
    days: '%d päivää',
    month: 'noin kuukausi',
    months: '%d kuukautta',
    year: 'noin vuosi',
    years: '%d vuotta',
  },
};
