module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="panel panel-default">\n  <div class="panel-body">\n    ';
 if (infoText) { ;
__p += '\n    <p class="text-warning">' +
((__t = ( infoText )) == null ? '' : __t) +
'</p>\n    ';
 } ;
__p += '\n    <p class="text-warning">' +
((__t = ( youSureText )) == null ? '' : __t) +
'</p>\n    <div class="form-remove-confirmation">\n      <button type="button" class="form-cancel-btn btn btn-default">' +
((__t = ( cancelBtnText )) == null ? '' : __t) +
'</button>\n      <button type="button" class="form-remove-btn btn btn-danger">' +
((__t = ( deleteBtnText )) == null ? '' : __t) +
'</button>\n    </div>\n\n    <div class="form-remove-progress progress hidden">\n      <div class="progress-bar progress-bar-striped active" role="progressbar" style="width: 100%">\n        <span class="sr-only">Waiting for server</span>\n      </div>\n    </div>\n  </div>\n</div>\n';

}
return __p
}