module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<span class="glyphicon glyphicon-' +
((__t = ( name )) == null ? '' : __t) +
'" aria-hidden="true"></span>\n';

}
return __p
}