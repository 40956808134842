module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<hr>\n\n<div class="password-reset-success hidden">\n  <div class="alert alert-success" role="alert">' +
((__t = ( __('pwd-reset-send-success') )) == null ? '' : __t) +
'</div>\n</div>\n\n<div class="password-reset-in-progress hidden">\n  <div class="progress">\n    <div class="progress-bar progress-bar-striped active" role="progressbar" style="width: 100%">\n      <span class="sr-only">Waiting for server</span>\n    </div>\n  </div>\n</div>\n\n<!-- Reset form -->\n<form class="password-reset-form">\n  <h3>' +
((__t = ( __('forgot-password-title') )) == null ? '' : __t) +
'</h3>\n  <p>' +
((__t = ( __('forgot-password-help') )) == null ? '' : __t) +
'</p>\n  <div class="form-group">\n    <label for="password-reset-email">' +
((__t = ( __('email-address') )) == null ? '' : __t) +
'</label>\n    <input type="email" class="form-control" id="password-reset-email">\n  </div>\n  <div class="pull-right">\n    <button type="submit" class="btn btn-primary">' +
((__t = ( __('send-email') )) == null ? '' : __t) +
'</button>\n  </div>\n  <div class="form-group">\n    <button type="button" class="password-reset-cancel btn btn-default">' +
((__t = ( __('cancel') )) == null ? '' : __t) +
'</button>\n  </div>\n</form>\n\n<div class="password-reset-invalid-email hidden">\n  <div class="alert alert-danger" role="alert">' +
((__t = ( __('pwd-reset-invalid-email') )) == null ? '' : __t) +
'</div>\n</div>\n\n<div class="password-reset-unknown-email hidden">\n  <div class="alert alert-danger" role="alert">' +
((__t = ( __('pwd-reset-unknown-email') )) == null ? '' : __t) +
'</div>\n</div>\n\n<div class="password-reset-server-error hidden">\n  <div class="alert alert-danger" role="alert">' +
((__t = ( __('pwd-reset-send-failed') )) == null ? '' : __t) +
'</div>\n</div>\n';

}
return __p
}