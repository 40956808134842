module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="login-container">\n  <div class="container-fluid">\n    <div class="row">\n      <div class="login-column login-glass ' +
((__t = ( sizeClass )) == null ? '' : __t) +
'">\n        <h1><img src="/assets/images/icons/128.png"> ' +
((__t = ( title )) == null ? '' : __t) +
'</h1>\n        <div class="login-form-container"></div>\n        <div class="password-reset-container hidden"></div>\n      </div>\n    </div><!-- .row -->\n  </div><!-- .container-fluid -->\n</div>\n';

}
return __p
}