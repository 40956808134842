module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<ul class="nav nav-tabs georap-tabs">\n  ';
 tabs.forEach(function (tab) { ;
__p += '\n  <li role="presentation" class="' +
((__t = ( tab.className )) == null ? '' : __t) +
'" data-tab="' +
((__t = ( tab.key )) == null ? '' : __t) +
'"><a href="#">' +
((__t = ( tab.title )) == null ? '' : __t) +
'</a></li>\n  ';
 }); ;
__p += '\n</ul>\n<div class="georap-tabs-spacer"></div>\n';

}
return __p
}