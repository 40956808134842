module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="login-incorrect hidden">\n  <div class="alert alert-danger" role="alert">' +
((__t = ( __('login-incorrect') )) == null ? '' : __t) +
'</div>\n</div>\n\n<div class="login-invalid-email hidden">\n  <div class="alert alert-warning" role="alert">' +
((__t = ( __('login-invalid-email') )) == null ? '' : __t) +
'</div>\n</div>\n\n<div class="login-invalid-password hidden">\n  <div class="alert alert-warning" role="alert">' +
((__t = ( __('login-empty-password') )) == null ? '' : __t) +
'</div>\n</div>\n\n<div class="login-server-error hidden">\n  <div class="alert alert-danger" role="alert">' +
((__t = ( __('login-failed') )) == null ? '' : __t) +
'</div>\n</div>\n\n<div class="login-deactivated hidden">\n  <div class="alert alert-warning" role="alert"><span class="glyphicon glyphicon-lock" aria-hidden="true"></span> <span class="alert-message">' +
((__t = ( __('login-deactivated') )) == null ? '' : __t) +
'</span></div>\n</div>\n\n<div class="login-in-progress hidden">\n  <div class="progress">\n    <div class="progress-bar progress-bar-striped progress-bar-' +
((__t = ( loginColor )) == null ? '' : __t) +
' active" role="progressbar" style="width: 100%">\n      <span class="sr-only">Waiting for server</span>\n    </div>\n  </div>\n</div>\n\n<form class="login-form">\n  <div class="form-group">\n    <label for="georap-login-email">' +
((__t = ( __('email-or-username') )) == null ? '' : __t) +
'</label>\n    <input type="text" class="form-control" id="georap-login-email" tabindex="1">\n  </div>\n  <div class="form-group">\n    <div class="pull-right">\n      <a class="password-reset-button" href="#">' +
((__t = ( __('forgot-password') )) == null ? '' : __t) +
'</a>\n    </div>\n    <label for="georap-login-password">' +
((__t = ( __('password') )) == null ? '' : __t) +
'</label>\n    <input type="password" class="form-control" id="georap-login-password" tabindex="2">\n  </div>\n  <div class="pull-right">\n    <button type="submit" class="btn btn-' +
((__t = ( loginColor )) == null ? '' : __t) +
'" tabindex="3">' +
((__t = ( __('log-in') )) == null ? '' : __t) +
'</button>\n  </div>\n</form>\n\n<p class="available-languages"></p>\n';

}
return __p
}